import React, { lazy } from "react";

const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About/About.js"));
const Research = lazy(() => import("../views/Research/Research"));
const SeeResearch = lazy(() => import("../views/Research/SeeResearch.jsx"));
const News = lazy(() => import("../views/News/News"));
const SeeNews = lazy(() => import("../views/News/seeNews.jsx"));
const Colaborators = lazy(() => import("../views/Colaborators/Colaborators"));
const Organizations = lazy(() => import("../views/Organizations/Organizations"));
const Login = lazy(() => import("../views/Login/Login"));
const Publications = lazy(() => import("../views/Publications/Publications"));
const Softwares = lazy(() => import("../views/ui/Softwares"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Events = lazy(() => import("../views/Events/Events"));
const Programs = lazy(() => import("../views/Programs/Programs"));
// const Map = lazy(() => import("../views/ui/Map"));
const UpdateProfile = lazy(() => import("../views/Colaborators/UpdateProfile"));

const InsectTrap = lazy(() => import("../views/TrapSystem/InsectTrap"));
const StoreInsectTrap = lazy(() => import("../views/TrapSystem/StoreInsectTrap"));
const UpdateInsectTrap = lazy(() => import("../views/TrapSystem/UpdateInsectTrap"));

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      /*{ path: "/", element: <Navigate to="/" /> },*/
      { path: "/", exact: true, element: <Starter /> },
      { path: "/sobre", exact: true, element: <About /> },
      { path: "/noticias", exact: true, element: <News /> },
      { path: '/noticias/:Newsid', exact: true, element: <SeeNews /> },
      { path: "/boletins", exact: true, element: <Research /> },
      { path: '/boletins/:Newsid', exact: true, element: <SeeResearch /> },
      { path: "/colaboradores", exact: true, element: <Colaborators /> },
      { path: "/editar-perfil/:Colaboradorid", exact: true, element: <UpdateProfile /> },
      { path: "/organizacoes", exact: true, element: <Organizations /> },
      { path: "/softwares", exact: true, element: <Softwares /> },
      { path: "/publicacoes", exact: true, element: <Publications /> },
      { path: "/alertas", exact: true, element: <Alerts /> },
      { path: "/programas", exact: true, element: <Programs /> },
      { path: "/eventos", exact: true, element: <Events /> },
      // { path: "/mapa", exact: true, element: <Map /> },
      { path: "/lancamentos-armadilhas", exact: true, element: <InsectTrap /> },
      { path: "/registrar-inseto-armadilha", exact: true, element: <StoreInsectTrap /> },
      { path: "/editar-inseto-armadilha/:InsectTrapid", exact: true, element: <UpdateInsectTrap /> },
    ],
  },
  {
    path: "/login",
    element: <Login />
  },
];

export default ThemeRoutes;